import { Table, Tag } from 'antd';
import PropTypes from 'prop-types';
import { CloseOutlined } from '@ant-design/icons';
import { ActionColor, IExtensionLog } from '../../data-access';
import { PrettyJson } from '../../ui';
import moment from 'moment';
import './extension-logs-table.scss';
import {current} from "@reduxjs/toolkit";

const baseClass = 'extension-logs-table';

interface IExtensionLogsTableProps {
  logs: IExtensionLog[];
  onChange: (pagination: { current?: number; pageSize?: number; total?: number; }, _: any, sorting: any) => void;
  onPaginationChange: (page: number, pageSize: number) => void;
  isLoading?: boolean;
  perPage: number;
  currentPage: number;
  total: number;
}

export const ExtensionLogsTable = ({
  logs,
  onChange,
  onPaginationChange,
  isLoading = false,
  perPage,
  currentPage,
  total
}: IExtensionLogsTableProps): JSX.Element => {
  const columns = [
    {
      title: '#',
      width: '100px',
      render: (_: any, record: IExtensionLog) => {
        return currentPage === 1 ? logs.indexOf(record) + 1 : (currentPage - 1) * perPage + logs.indexOf(record) + 1;
      }
    },
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
      render: (id: string) => {
        return <Tag color="green"><b>{id}</b></Tag>
      }
    },
    {
      title: 'Date',
      sorter: (a: IExtensionLog, b: IExtensionLog) => moment(a.created_at).unix() - moment(b.created_at).unix(),
      render: (record: IExtensionLog) => {
        const formattedDate = moment(record?.created_at || record?.updated_at).format('DD-MM-YYYY hh:mm:ss');
        return formattedDate || <CloseOutlined />;
      }
    },
    {
      title: 'Action',
      dataIndex: 'action',
      key: 'action',
      sorter: (a: IExtensionLog, b: IExtensionLog) => a.action.length - b.action.length,
      render: (action: string) => {
        return <Tag color={ActionColor[action as keyof typeof ActionColor]}><b>{action}</b></Tag>|| <CloseOutlined />;
      }
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
      sorter: (a: IExtensionLog, b: IExtensionLog) => String(a.email).length - String(b.email).length,
      render: (email: string) => {
        return email ?? <Tag color="red">Not specified</Tag>;
      }
    },
    {
      title: 'Method',
      dataIndex: 'method',
      key: 'method',
      render: (method: string) => {
        return <pre>{method}</pre>;
      }
    },
    {
      title: 'Logs',
      dataIndex: 'data',
      key: 'data',
      render: (dataSource: { [key: string]: string }) => {
        const json = JSON.stringify(dataSource)
          .replace(/\\/g, '')
          .replace('""', '')
          .replace('{}', '"{}"')
          .replace('"",', ',');
        return `${json.substring(0, 25)}...` || <CloseOutlined />;
      }
    },
  ];

  return (
    <div className={baseClass}>
      <Table
        tableLayout="fixed"
        columns={columns}
        expandable={{
          expandedRowRender: (record: IExtensionLog) => {
            console.warn('expanded row render = ', record);
            return <div className="expandable-content">
              <PrettyJson json={record.data} />
            </div>
          },
          rowExpandable: (record: IExtensionLog) => !!record?.data
        }}
        dataSource={logs}
        onChange={onChange}
        loading={isLoading}
        pagination={{
          total,
          current: currentPage,
          pageSize: perPage,
          pageSizeOptions: ['15', '20', '30', '50'],
          onChange: onPaginationChange
        }}
      />
    </div>
  );
}

ExtensionLogsTable.propTypes = {
  logs: PropTypes.array.isRequired
};