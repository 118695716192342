import { baseUrl, handleResponse } from "./base";
import { ISubscription, LicencePlan } from "../data-access";

const requestOptions = {
  method: "GET",
  mode: "cors",
  cache: "no-cache",
  credentials: "same-origin",
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
  },
} as any;

const fetchSubscriptions = (
  token: string
): Promise<{
  data: { plan: LicencePlan; subscriptions: ISubscription[] }[];
}> => {
  return fetch(`${baseUrl}api/subscriptions`, {
    ...requestOptions,
    headers: {
      ...requestOptions.headers,
      Authorization: `Bearer ${token}`,
    },
  }).then(handleResponse);
};

// const fetchSubscriptionsGraphs = (token: string, year: string): Promise<IFetchSubscriptionsGraphsResponse> => {
//   return fetch(`${baseUrl}api/dashboard/subscriptions/graphs?year=${year}`, {
//     ...requestOptions,
//     headers: {
//       ...requestOptions.headers ,
//       'Authorization': `Bearer ${token}`
//     }
//   }).then(handleResponse);
// }
//
// const fetchSubscriptionsBlocks = (token: string): Promise<IFetchSubscriptionsBlocks> => {
//   return fetch(`${baseUrl}api/dashboard/subscriptions/blocks`, {
//     ...requestOptions,
//     headers: {
//       ...requestOptions.headers ,
//       'Authorization': `Bearer ${token}`
//     }
//   }).then(handleResponse);
// }

// TODO: temporary solution
const temporarySubscriptionBlocks = (token: string): Promise<any> => {
  return fetch(`${baseUrl}api/dashboard/subscriptions/blocks-feature`, {
    ...requestOptions,
    headers: {
      ...requestOptions.headers,
      Authorization: `Bearer ${token}`,
    },
  }).then(handleResponse);
};

// TODO: temporary endpoint
const temporarySubscriptionGraphs = (
  token: string,
  year: string,
  newOnly = false
): Promise<any> => {
  return fetch(
    `${baseUrl}api/dashboard/subscriptions/graphs-feature?year=${year}&newOnly=${Number(
      newOnly
    )}`,
    {
      ...requestOptions,
      headers: {
        ...requestOptions.headers,
        Authorization: `Bearer ${token}`,
      },
    }
  ).then(handleResponse);
};

const activeUsers = (token: string, days: string): Promise<any> => {
  return fetch(
    `${baseUrl}api/stats/active-users?activeUsersPeriodDays=${days}`,
    {
      ...requestOptions,
      headers: {
        ...requestOptions.headers,
        Authorization: `Bearer ${token}`,
      },
    }
  ).then(handleResponse);
};

export const subscriptionsApi = {
  fetchSubscriptions,
  // fetchSubscriptionsGraphs,
  // fetchSubscriptionsBlocks,
  // TODO: temporary endpoint
  temporarySubscriptionBlocks,
  temporarySubscriptionGraphs,
  activeUsers,
};
