import React from 'react';
import { Table, Modal } from 'antd';
import { FaCog, FaTrash, FaBan } from 'react-icons/fa';
import { IPromoCode } from '../../../data-access';
import './discounts-table.scss';

const { confirm } = Modal;

const baseClassName = 'discounts-table';

interface IDiscountTableProps {
  data: IPromoCode[];
  isLoading?: boolean;
  total: number;
  currentPage?: number;
  perPage?: number;
  onDelete: (discountId: number) => void;
  onEdit: (discountId: number) => void;
}

const TableFooter = ({ total }: { total: number }): JSX.Element => {
  return (
    <div className="table-footer">
      <strong>Total:</strong> {total}
    </div>
  );
}

export const DiscountsTable = ({
  data,
  isLoading = false,
  total,
  currentPage = 1,
  perPage = 10,
  onDelete,
  onEdit
}: IDiscountTableProps): JSX.Element => {
  const showConfirm = (discount: IPromoCode): void => {
    confirm({
      title: `Are you sure you want to delete "${discount.name}" discount?`,
      okText: 'Delete',
      async onOk() {
        onDelete(discount.id);
        return new Promise((resolve) => resolve(true))
      },
      onCancel() {},
    });
  }

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      render: (name: string) => <span>{name}</span>,
    },
    {
      title: 'Discount',
      dataIndex: 'discount',
      key: 'discount',
      render: (discount: number) => <span>{discount}%</span>,
    },
    {
      title: 'Start Date',
      dataIndex: 'fromDate',
      key: 'fromDate',
      render: (value: string) => {
        return value ? <span>{value}</span> : <FaBan />
      }
    },
    {
      title: 'End Date',
      key: 'toDate',
      dataIndex: 'toDate',
      render: (value: string) => {
        return value ? <span>{value}</span> : <FaBan />
      }
    },
    {
      title: 'Plans',
      key: 'plans',
      dataIndex: 'plans',
      render: (value: any, record: any) => {
        const plans = [
          { name: 'Starter', checked: record.isStarter },
          { name: 'Yearly', checked: record.isYearly },
        ];

        const filteredPlans = plans
          .filter((record: { name: string, checked: boolean }) => record.checked)
          .map((record: { name: string, checked: boolean }) => record.name)
          .join(', ') || <FaBan />;

        return <span>{filteredPlans}</span>
      },
    },
    {
      title: 'Action',
      key: 'action',
      render: (value: any, record: any, index: number) => (
        <div className="control-buttons">
          <button onClick={() => onEdit(data[index].id)}><FaCog /></button>
          <button onClick={() => showConfirm(data[index])}><FaTrash /></button>
        </div>
      ),
    },
  ];

  return (
    <div className={baseClassName}>
      <Table
        columns={columns}
        dataSource={data}
        footer={() => <TableFooter total={data.length} />}
        loading={isLoading}
        pagination={{
          total,
          current: currentPage,
          pageSize: perPage
        }}
      />
    </div>
  );
}