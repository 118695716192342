import { ChangeEvent } from 'react';
import { Controller, useForm } from 'react-hook-form';
import dayjs, { Dayjs } from 'dayjs';
import debounce from 'lodash.debounce';
import { IBackendLogsFilter } from '../../data-access';
import { FormButton, FormInput, FormSelect, FormDatePicker } from '../../ui/form';
import './backend-logs-filter.scss';

const baseClass = 'backend-logs-filter';

interface IBackendLogsFilterProps {
  onApply: (filter: IBackendLogsFilter) => void;
}

const actionFilterOptions = ['GET', 'POST', 'DELETE', 'PATCH'].map((method: string) => ({
  label: method, value: method
}))
actionFilterOptions.unshift({ label: 'All', value: 'all' });

type FieldNameType = 'id' | 'email' | 'path' | 'response_code' | 'created_at' | 'method';

const filterInputFields = ['id', 'email',  'path', 'response_code'];

export const BackendLogsFilter = ({ onApply }: IBackendLogsFilterProps): JSX.Element => {
  const formDefaultValues = {
    id: '',
    method: 'all',
    email: '',
    path: '',
    response_code: '',
    created_at: '',
  };

  const form = useForm({
    mode: 'onSubmit',
    defaultValues: formDefaultValues,
  });

  const { control, handleSubmit, setValue, reset, formState: { errors, dirtyFields } } = form;

  const handleInputFilterChange = debounce(({ name, value }: { name: FieldNameType, value: string }) => {
    setValue(name, value, { shouldDirty: true, shouldValidate: true });
  }, 300);

  const handleSelectFilterChange = ({ name, value }: { name: FieldNameType, value: string }): void => {
    setValue(name, value, { shouldDirty: true, shouldValidate: true });
  }

  const handleFilterApply = (filter: IBackendLogsFilter): void => {
    if (filter.method === 'all') {
      filter.method = '';
    }
    onApply(filter);
  }

  const isFormValid = Object.keys(dirtyFields).length > 0 && !errors?.email?.message;

  return (
    <div className={baseClass}>
      <form className={`${baseClass}__container`} onSubmit={handleSubmit(handleFilterApply)}>
        {filterInputFields.map((fieldName: string) => {
          const fieldType = fieldName === 'email' ? 'email' : 'search';
          return (
            <Controller
              key={fieldName}
              control={control}
              name={fieldName as FieldNameType}
              render={({ field: { name, value, onChange, ref } }) => (
                <FormInput
                  allowClear={true}
                  ref={ref}
                  value={String(value) || ''}
                  label={fieldName}
                  key={fieldName}
                  type={fieldType}
                  name={name}
                  error={fieldName === 'email' ? errors?.email?.message : undefined}
                  placeholder={`Please specify ${fieldName}`}
                  onChange={(event: ChangeEvent<HTMLInputElement>) => {
                    onChange(event.target.value);
                    handleInputFilterChange({ name: fieldName as FieldNameType, value: event.target.value });
                  }}
                />
              )}
            />
          )
        })}
        <Controller
          control={control}
          name="created_at"
          render={({ field: { name, onChange, value } }) => (
            <FormDatePicker
              value={value ? dayjs(value) : dayjs()}
              label="created_at"
              disableFutureDates={true}
              onChange={(date: Dayjs | null, dateString: string) => {
                onChange(dateString);
                setValue(name, dateString, { shouldDirty: true, shouldValidate: true });
              }}
            />
          )}
        />
        <Controller
          control={control}
          name="method"
          render={({ field: { name, onChange, value, ref } }) => (
            <FormSelect
              ref={ref}
              value={value || ''}
              label="method"
              allowClear={true}
              placeholder="Please select an action"
              options={actionFilterOptions}
              onChange={(value: string) => {
                onChange(value);
                handleSelectFilterChange({ name, value });
              }}
            />
          )}
        />
        <FormButton
          htmlType="submit"
          disabled={!isFormValid}
        >
          Apply
        </FormButton>
        <FormButton
          htmlType="button"
          disabled={Object.keys(dirtyFields).length === 0}
          onClick={() => {
            reset(formDefaultValues , { keepValues: false });
            handleFilterApply(formDefaultValues);
          }}
        >
          Reset
        </FormButton>
      </form>
    </div>
  );
}