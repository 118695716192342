import React from "react";
import { ISubscription, LicencePlan, UsersCard } from "../../../data-access";
import { Expandable } from "../../../ui";
import { SubscriptionDetailsTable } from "../subscription-details-table";
import "./subscriptions-table.scss";

const baseClassName = "subscriptions-table";

interface ISubscriptionsTableProps {
  data: { plan: LicencePlan; subscriptions: ISubscription[] }[];
  onDetailsSort: (
    plan: LicencePlan,
    field: string,
    order: "asc" | "desc"
  ) => void;
}

export const SubscriptionsTable = ({
  data,
  onDetailsSort,
}: ISubscriptionsTableProps): JSX.Element => {
  const getPlanSubscriptions = (plan: string): any[] => {
    const targetPlan = data.find(
      (record: { plan: string; subscriptions: any[] }) => record?.plan === plan
    );
    return targetPlan ? targetPlan.subscriptions : [];
  };

  return (
    <div className={baseClassName}>
      <Expandable title="Paid Users">
        <SubscriptionDetailsTable
          plan="Trial"
          data={getPlanSubscriptions(UsersCard.paidUsers)}
          onDetailsSort={onDetailsSort}
        />
      </Expandable>
      <Expandable title="Trial Users">
        <SubscriptionDetailsTable
          plan="Lite"
          data={getPlanSubscriptions(UsersCard.trialUsers)}
          onDetailsSort={onDetailsSort}
        />
      </Expandable>
      <Expandable title="Active Users">
        <SubscriptionDetailsTable
          plan="Starter"
          data={getPlanSubscriptions(UsersCard.activeUsers)}
          onDetailsSort={onDetailsSort}
        />
      </Expandable>
      <Expandable title="Pipedrive Users">
        <SubscriptionDetailsTable
          plan="Yearly"
          data={getPlanSubscriptions(UsersCard.pipedriveUsers)}
          onDetailsSort={onDetailsSort}
        />
      </Expandable>
    </div>
  );
};
