import React from "react";
import { BrowserRouter, Navigate, Routes, Route } from "react-router-dom";
import { useSelector } from "react-redux";
import { APP_ROUTES } from "./data-access";
import {
  BackendLogs,
  ExtensionLogs,
  Login,
  NothingFound,
  Discounts,
  Subscriptions,
} from "./features";
import { RootState } from "./store";
import { PrivateRoute, ProtectedRoute } from "./ui";

const baseClass = "upwex-dashboard";

function App() {
  const isUserAuthorized = useSelector(
    (state: RootState) => state.auth?.auth_token
  );

  return (
    <div className={baseClass}>
      <BrowserRouter>
        <Routes>
          <Route
            path={APP_ROUTES.home}
            element={<Navigate to={APP_ROUTES.login} />}
          />
          <Route
            path={APP_ROUTES.login}
            element={
              <ProtectedRoute renderCondition={!isUserAuthorized}>
                <Login />
              </ProtectedRoute>
            }
          />
          <Route
            path={APP_ROUTES.extensionLogs}
            element={
              <PrivateRoute>
                <ExtensionLogs />
              </PrivateRoute>
            }
          />
          <Route
            path={APP_ROUTES.backendLogs}
            element={
              <PrivateRoute>
                <BackendLogs />
              </PrivateRoute>
            }
          />
          <Route
            path={APP_ROUTES.discounts}
            element={
              <PrivateRoute>
                <Discounts />
              </PrivateRoute>
            }
          />
          <Route
            path={APP_ROUTES.users}
            element={
              <PrivateRoute>
                <Subscriptions />
              </PrivateRoute>
            }
          />
          <Route path="*" element={<NothingFound />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
