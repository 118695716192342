import { ReactNode } from 'react';
import PropTypes from 'prop-types';
import { Spin } from 'antd';
import './form-button.scss';

interface IFormButtonProps {
  className?: string;
  theme?: 'brand' | 'red' | 'green' | 'blue';
  htmlType?: 'button' | 'submit' | 'reset' | undefined;
  disabled?: boolean;
  loading?: boolean;
  onClick?: () => void;
  onMouseEnter?: () => void;
  onMouseLeave?: () => void;
  children: ReactNode;
}

export const FormButton = ({
  className = '',
  theme = 'brand',
  htmlType = 'button',
  disabled = false,
  onClick,
  onMouseEnter = () => null,
  onMouseLeave = () => null,
  loading = false,
  children
}: IFormButtonProps): JSX.Element => {

  const handleOnClick = (): void => {
    onClick && onClick();
  }

  return (
    <button
      className={`${className} ${theme} button`}
      type={htmlType}
      disabled={disabled}
      onClick={handleOnClick}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      {children}
      {loading && <Spin size="small" />}
    </button>
  );
}

FormButton.propTypes = {
  className: PropTypes.string,
  type: PropTypes.string,
  theme: PropTypes.string,
  htmlType: PropTypes.string,
  disabled: PropTypes.bool,
  loading: PropTypes.bool,
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  onClick: PropTypes.func,
  onMouseEnter: PropTypes.func,
  onMouseLeave: PropTypes.func,
};