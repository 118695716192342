import PropTypes from 'prop-types';

interface IPrettyJsonProps {
  json: any;
}

export const PrettyJson = ({ json }: IPrettyJsonProps): JSX.Element => (
  <pre>
    {
      JSON.stringify(json, null, 2)
        .replace(/\\/g, '')
        .replace('""', '')
        .replace('{}', '"{}"')
        .replace('"",', ',')
    }
  </pre>
);

PrettyJson.propTypes = {
  json: PropTypes.any.isRequired
};