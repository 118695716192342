import PropTypes from 'prop-types';
import { FaCheck } from 'react-icons/fa';
import './form-checkbox.scss';

export interface FormCheckboxProps {
  children?: React.ReactNode;
  value?: string | boolean;
  disabled?: boolean;
  name: string;
  className?: string;
  onChange?: (value: any, name: string, event: React.SyntheticEvent) => void;
  error?: React.ReactNode;
}

export function FormCheckbox({ children, value, disabled, name, className, onChange, error }: FormCheckboxProps) {

  const changeHandler = (event: React.SyntheticEvent) => {
    onChange && onChange((event.target as HTMLInputElement).value, name, event);
  };

  const rootClass = `form-checkbox ${className} ${disabled ? 'disabled' : ''} ${ error ? 'error' : '' }`;

  return (
    <div className={rootClass}>
      <div className="form-checkbox__inner">
        <label className="form-checkbox__icon">
          <input hidden type="checkbox" disabled={disabled} name={name} checked={!!value} onChange={changeHandler} />
          <FaCheck />
        </label>
        <div className="form-checkbox__text">{children}</div>
      </div>
      {error && <p className="field-input__error">{error}</p>}
    </div>
  );
}

FormCheckbox.propTypes = {
  children: PropTypes.node,
  value: PropTypes.bool,
  disabled: PropTypes.bool,
  name: PropTypes.string,
  className: PropTypes.string,
  onChange: PropTypes.func,
  error: PropTypes.node,
};

export default FormCheckbox;
