import React, { FocusEvent, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Controller, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import { authApi } from '../../api/auth';
import {
  APP_ROUTES,
  ILoginResponse,
  VALID_EMAIL_REGEX,
  VALIDATION_MESSAGES
} from '../../data-access';
import { storeAuthActions } from '../../store/slices/auth';
import { FormInput, FormButton, BrandLogo } from '../../ui';
import { notify } from '../../utils';
import * as yup from 'yup';
import './login.scss';

interface ILoginFormData {
  email: string;
  password: string;
}

const validationScheme = yup.object({
  email: yup.string().required('Email is required field.').matches(VALID_EMAIL_REGEX, VALIDATION_MESSAGES.INVALID_EMAIL),
  password: yup.string().required('Password is required field.'),
}).required();

const baseClass = 'login';

export const Login = (): JSX.Element => {
  const [isFetching, setIsFetching] = useState<boolean>(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const form = useForm<ILoginFormData>({
    mode: 'onBlur',
    defaultValues: {
      email: '',
      password: ''
    },
    resolver: yupResolver(validationScheme),
  });
  const { control, handleSubmit, setValue, formState: { errors, dirtyFields } } = form;

  const handleFormSubmit = ({ email, password }: ILoginFormData): void => {
    setIsFetching(true);
    authApi.login({ email, password })
      .then((response: ILoginResponse) => {
        dispatch(storeAuthActions.setUserData(response.data));
        navigate(APP_ROUTES.extensionLogs);
      })
      .catch((error: { status: number, message: string }) => {
        notify('error', error.message);
      }).finally(() => setIsFetching(false));
  }

  const handleEmailInput = (e: FocusEvent<HTMLInputElement>): void => {
    setValue('email', e?.target?.value, { shouldDirty: true, shouldValidate: true });
  }

  const handlePasswordInput = (e: FocusEvent<HTMLInputElement>): void => {
    setValue('password', e?.target?.value, { shouldDirty: true, shouldValidate: true });
  }

  const isFormValid = Object.keys(dirtyFields).length > 0 && !errors?.email?.message && !errors?.password?.message;

  return (
    <div className={baseClass}>
      <BrandLogo />
      <div className={`${baseClass}__content`}>
        <h1 className={`${baseClass}__title`}>Sign In</h1>
        <p className={`${baseClass}__description`}>
          Connect using the email address and password.
        </p>
        <form className={`${baseClass}__form`} onSubmit={handleSubmit(handleFormSubmit)}>
          <Controller
            control={control}
            name="email"
            render={({ field: { name, onBlur, value, ref } }) => (
              <FormInput
                ref={ref}
                placeholder="Enter your email address"
                error={errors?.email?.message}
                name={name}
                value={value}
                onBlur={onBlur}
                onChange={handleEmailInput}
              />
            )}
          />
          <Controller
            control={control}
            name="password"
            render={({ field: { name, onBlur, value, ref } }) => (
              <FormInput
                ref={ref}
                type="password"
                placeholder="Enter your password"
                error={errors?.password?.message}
                name={name}
                value={value}
                onBlur={onBlur}
                onChange={handlePasswordInput}
              />
            )}
          />
          <FormButton
            className={`${baseClass}__button`}
            type="primary"
            htmlType="submit"
            loading={isFetching}
            disabled={!isFormValid || isFetching}
          >
            Sign In
          </FormButton>
        </form>
      </div>
    </div>
  );
}