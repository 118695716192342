import { baseUrl, handleResponse } from './base';
import { ILoginParams, ILoginResponse } from '../data-access';

const login = ({ email, password }: ILoginParams): Promise<ILoginResponse> => {
  return fetch(`${baseUrl}api/auth/login`, {
    method: 'POST',
    mode: 'cors',
    cache: 'no-cache',
    credentials: 'same-origin',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ email, password })
  }).then(handleResponse);
}

const logout = ({ token }: { token: string }): Promise<void> => {
  return fetch(`${baseUrl}api/auth/logout`, {
    method: 'GET',
    mode: 'cors',
    cache: 'no-cache',
    credentials: 'same-origin',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`
    }
  }).then(handleResponse);
}

export const authApi = {
  login,
  logout
};