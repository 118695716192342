const prefix = 'upwex_dashboard_';

export const storage = {
  set: (key: string, item: unknown | string | number | boolean): void => {
    if (typeof item === 'string' || typeof item === 'number' || typeof item === 'boolean') {
      window.localStorage.setItem(`${prefix}${key}`, item.toString());
    } else {
      window.localStorage.setItem(`${prefix}${key}`, JSON.stringify(item));
    }
  },
  get: <T>(key: string): T | string => {
    let value: string;

    try {
      value = JSON.parse(window.localStorage.getItem(`${prefix}${key}`) as string);
    } catch {
      value = window.localStorage.getItem(`${prefix}${key}`) as string;
    }

    return value;
  },
  remove: (key: string): void => {
    window.localStorage.removeItem(`${prefix}${key}`);
  },
  clear: (): void => {
    Object.keys(window.localStorage)
      .filter((key: string) => key.startsWith(prefix))
      .forEach((key: string) => window.localStorage.removeItem(key));
  },
};

export default storage;