import { configureStore } from '@reduxjs/toolkit';
import authReducer from './slices/auth';
import { storage } from '../utils';

export const appStateKey = 'auth';

// middlewares
const localStorageMiddleware = ({ getState }: any) => {
  return (next: any) => (action: any) => {
    const result = next(action);
    storage.set(appStateKey, getState());
    return result;
  }
}

const reHydrateStore = () => {
  const appState = storage.get(appStateKey);

  if (appState !== null) {
    return appState;
  }
}

export const store = configureStore({
  reducer: {
    auth: authReducer,
  },
  preloadedState: reHydrateStore(),
  middleware: (getDefaultMiddleware) => {
    return getDefaultMiddleware().concat(localStorageMiddleware);
  },
});

export type RootState = ReturnType<typeof store.getState>;