import PropTypes from 'prop-types';
import { ForwardedRef, forwardRef } from 'react';
import { DatePicker } from 'antd';
import dayjs, { Dayjs } from 'dayjs';
import './form-datepicker.scss';

interface IFormDatePickerProps {
  name?: string;
  value?: Dayjs;
  label?: string;
  format?: string;
  error?: string;
  disabled?: boolean;
  disablePastDates?: boolean;
  disableFutureDates?: boolean;
  onBlur?: () => void;
  onChange: (value: Dayjs | null, dateString: string) => void;
}

export const FormDatePicker = forwardRef(({ name, value, label, error, format = 'YYYY-MM-DD', disabled = false, disableFutureDates = false, disablePastDates = false, onBlur, onChange }: IFormDatePickerProps, ref: ForwardedRef<any>): JSX.Element => {
  return (
    <div className="field-date-picker">
      {label && <label className="field-date-picker__label">{label}</label>}
      <DatePicker
        ref={ref}
        status={error ? 'error' : undefined}
        name={name}
        defaultValue={value || undefined}
        format={format}
        disabled={disabled}
        disabledDate={disablePastDates ? (current) => current.isBefore(dayjs().subtract(1, 'day')) : disableFutureDates ? (current) => current.isAfter(dayjs()) : undefined}
        onChange={onChange}
        onBlur={onBlur}
      />
      {error && <p className="field-date-picker__error">{error}</p>}
    </div>
  );
});

FormDatePicker.propTypes = {
  name: PropTypes.string,
  value: PropTypes.any.isRequired,
  format: PropTypes.string,
  disabled: PropTypes.bool,
  disablePastDates: PropTypes.bool,
  disableFutureDates: PropTypes.bool,
  error: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func,
};