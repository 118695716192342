import * as yup from 'yup';

export const validationScheme = yup.object({
  name: yup.string().max(100, 'Name can be max 100 characters long.').required('Name is required field.'),
  discount: yup.number().required('Discount is required field.'),
  startDate: yup.string().required('Start Date is required field.'),
  endDate: yup.date().when('startDate', (val: any, schema: any) => {
    if (val[0]) {
      const startDate = new Date(val);
      startDate.setDate(startDate.getDate() + 1);
      return val && schema.min(startDate, "End date should be greater than start date.");
    }
  }).required('End Date is required field.'),
  plans: yup.array()
    .of(
      yup.object().shape({
        name: yup.string(),
        checked: yup.boolean(),
      })
    )
    .compact((value) => !value.checked)
    .min(1, 'Plans is required field.'),
}).required();