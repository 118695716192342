import './brand-logo.scss';

export const BrandLogo = (): JSX.Element => {
  return (
    <div className="brand-logo">
      <span className="brand-logo__avatar">
        <img src="/upwex-logo.svg" alt="Upwex.io" />
      </span>
    </div>
  );
}