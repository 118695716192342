import React, { useState } from "react";
import { FaChevronDown, FaChevronUp } from "react-icons/fa";
import { ISubscription, LicencePlan } from "../../../data-access";
import "./subscription-details-table.scss";
import dayjs from "dayjs";
import { Tag } from "antd";

interface ISubscriptionDetailsTableProps {
  plan: LicencePlan;
  data: ISubscription[];
  onDetailsSort: (
    plan: LicencePlan,
    field: string,
    order: "asc" | "desc"
  ) => void;
}

interface IMutatedRow {
  [key: number]: boolean;
}

const baseClassName = "subscription-details-table";

const dateFormat = "DD.MM.YYYY HH:MM";

export const SubscriptionDetailsTable = ({
  plan,
  data,
  onDetailsSort,
}: ISubscriptionDetailsTableProps): JSX.Element => {
  const [mutatedRows, setMutatedRows] = useState<IMutatedRow>(
    {} as IMutatedRow
  );
  const [columns, setColumns] = useState<
    {
      name: string;
      title: string;
      isSortable: boolean;
      order?: "asc" | "desc";
    }[]
  >([
    { name: "full_name", title: "Name", isSortable: false, order: "desc" },
    { name: "email", title: "Email", isSortable: false, order: "desc" },
    { name: "role", title: "Role", isSortable: false, order: "desc" },
    {
      name: "stripe_customer_id",
      title: "Stripe ID",
      isSortable: false,
      order: "desc",
    },
    {
      name: "created_at",
      title: "Start Date",
      isSortable: false,
      order: "desc",
    },
    {
      name: "automatic_job_ai_assistant",
      title: "Enable AI Job Assistant",
      isSortable: false,
      order: "desc",
    },
  ]);

  const handleDetailsSort = (field: string, order: "asc" | "desc"): void => {
    onDetailsSort(plan, field, order);
  };

  return (
    <div className={baseClassName}>
      <div className={`${baseClassName}__heading`}>
        {columns.map((column, index) => (
          <div
            className={`${baseClassName}__cell`}
            onClick={() => {
              const columnsClose = [...columns];
              const sortOrder = !column.order
                ? "desc"
                : column.order === "asc"
                ? "desc"
                : "asc";
              columnsClose[index].order = sortOrder;
              setColumns(columns);
              handleDetailsSort(column.name, sortOrder);
            }}
          >
            {column.title}{" "}
            {column.isSortable && (
              <div>
                {column.order === "desc" ? <FaChevronDown /> : <FaChevronUp />}
              </div>
            )}
          </div>
        ))}
      </div>
      <div className={`${baseClassName}__body`}>
        {data.map((record: any, index: number) => (
          <div key={`record-${index}`} className={`${baseClassName}__record`}>
            <div className={`${baseClassName}__row`}>
              <div className={`${baseClassName}__cell picture`}>
                {record.picture ? (
                  <img src={record.picture} alt="picture" />
                ) : null}
                {record.full_name || "-"}
              </div>
              <div className={`${baseClassName}__cell`}>{record.email}</div>
              <div className={`${baseClassName}__cell`}>{record.role}</div>
              <div className={`${baseClassName}__cell`}>
                {record.stripe_customer_id}
              </div>
              <div className={`${baseClassName}__cell`}>
                {dayjs(record.created_at).format(dateFormat)}
              </div>
              <div className={`${baseClassName}__cell`}>
                {record.automatic_job_ai_assistant ? (
                  <Tag color="green">Yes</Tag>
                ) : (
                  <Tag color="red">No</Tag>
                )}
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};
