import { Dropdown } from 'antd';
import { useUser } from '../../hooks';
// @ts-ignore
import { ReactComponent as AdminProfileSVG } from '../../assets/images/admin_profile.svg';
import './top-bar-panel.scss';

const baseClass = 'top-bar';

export const TopBarPanel = (): JSX.Element => {
  const { resetUser } = useUser();

  const items = [
    {
      key: 'logout',
      label: (
        <span className="logout-button" onClick={resetUser}>
          Logout
        </span>
      ),
    }
  ];

  return (
    <div className={baseClass}>
      <Dropdown menu={{ items }}>
        <div className={`${baseClass}__avatar-container`}>
          <div className={`${baseClass}__user-details`}>
            <span className={`${baseClass}__details`}>{'Upwex Admin'}</span>
            <span className={`${baseClass}__details`}>{'admin@logs.upwex.io'}</span>
          </div>
          <AdminProfileSVG className={`${baseClass}__avatar`} />
          <span className={`${baseClass}__online-indicator`} />
        </div>
      </Dropdown>
    </div>
  );
}