import { FaUsers } from "react-icons/fa";
import { Spin } from "antd";
import {
  SubscriptionPlanColor,
  ISubscriptionsBlock,
} from "../../../data-access";
import "./subscription-card.scss";

interface ISubscriptionCardProps {
  data: ISubscriptionsBlock;
  isLoading?: boolean;
}

const baseClassName = "subscription-card";

export const SubscriptionCard = ({
  data,
  isLoading = false,
}: ISubscriptionCardProps): JSX.Element => {
  const { name, subscriptions, type } = data;

  return (
    <div className={baseClassName}>
      {isLoading && <Spin />}
      {!isLoading && (
        <>
          <div className={`${baseClassName}__heading`}>
            {name}
            <div
              className={`${baseClassName}__icon`}
              style={{
                background:
                  SubscriptionPlanColor[
                    type as keyof typeof SubscriptionPlanColor
                  ],
              }}
            >
              <FaUsers />
            </div>
          </div>
          <span className={`${baseClassName}__value`}>
            {subscriptions} <label>users</label>
          </span>
        </>
      )}
    </div>
  );
};
