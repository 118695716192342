import React, { useRef } from 'react';
import { Spin } from 'antd';
import PropTypes from 'prop-types';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { Bar, getElementsAtEvent, getElementAtEvent } from 'react-chartjs-2';
import './bar-chart.scss';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

interface IBarChartDataSet {
  label: string;
  data: number[],
  backgroundColor: string;
}

interface IBarChartProps {
  onBarClick?: (metricIndex: number, valueIndex: number) => void;
  datasets?: IBarChartDataSet[];
  isLoading?: boolean;
  labels?: string[];
  height?: string;
  allowMoneySign?: boolean;
}

export const BarChart = ({ onBarClick = () => null, isLoading = false, allowMoneySign = false, height = '340px', labels = [], datasets = [] }: IBarChartProps): JSX.Element => {
  const chartRef = useRef();

  const options = {
    maintainAspectRatio: false,
    responsive: true,
    scales: {
      x: {
        grid: {
          display: false,
        },
        ticks: {
          font: {
            size: 11,
            weight: 'bold' as any,
            color: '#656565' as any,
          }
        }
      },
      y: {
        display: false,
        grid: {
          display: false,
        },
        ticks: {
          font: {
            size: 11,
            weight: 'bold' as any,
            color: '#656565' as any,
          }
        },
      }
    },
    plugins: {
      datalabels: {
        display: true,
        color: '#656565',
        formatter: (value: number) => {
          return allowMoneySign ? `$${value}` : Math.round(value) ;
        },
        anchor: 'end' as any,
        offset: -20,
        align: 'start' as any,
        font: {
          size: 11,
          weight: 'bold' as any,
        },
      },
      legend: {
        position: 'top' as const,
        labels: {
          font: {
            size: 11,
            weight: 'bold' as any,
          },
          color: '#656565',
        },
      },
      title: {
        display: false,
      }
    }
  };

  const generateChartData = (): { labels: string[], datasets: IBarChartDataSet[] } => {
    return {
      labels,
      datasets,
    };
  }

  const handleBarClick = (event: any): void => {
    if (chartRef.current) {
      const clickedBar = getElementAtEvent(chartRef.current, event);
      if (clickedBar[0]) {
        const datasetIndex = clickedBar[0].datasetIndex;
        const dataPoint = clickedBar[0].index;
        onBarClick(datasetIndex, dataPoint);
      }
    }
  }

  return (
    <div className={`bar-chart-container ${isLoading ? 'fetching' : ''}`}>
      {isLoading && <Spin size="large" />}
      {!isLoading && (
        <Bar
          ref={chartRef}
          height={height}
          options={options}
          plugins={[ChartDataLabels]}
          data={generateChartData()}
          onClick={handleBarClick}
        />
      )}
    </div>
  )
};

BarChart.propTypes = {
  datasets: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string.isRequired,
    data: PropTypes.arrayOf(PropTypes.number),
    backgroundColor: PropTypes.string.isRequired,
  })),
  labels: PropTypes.arrayOf(PropTypes.string),
  isLoading: PropTypes.bool,
  height: PropTypes.number
};