import { Table, Tag } from 'antd';
import { CloseOutlined } from '@ant-design/icons';
import moment from 'moment';
import { IBackendLog, MethodColor } from '../../data-access';
import { PrettyJson } from '../../ui';
import './backend-logs-table.scss';

const baseClass = 'backend-logs-table';

interface IBackendLogsTableProps {
  logs: IBackendLog[];
  onChange: (pagination: { current?: number; pageSize?: number; total?: number; }, _: any, sorting: any) => void;
  onPaginationChange: (page: number, pageSize: number) => void;
  isLoading?: boolean;
  perPage: number;
  currentPage: number;
  total: number;
}


export const BackendLogsTable = ({ logs, onChange, onPaginationChange, isLoading = false, currentPage, perPage, total }: IBackendLogsTableProps): JSX.Element => {
  const columns = [
    {
      title: '#',
      width: '100px',
      render: (_: any, record: IBackendLog) => {
        return currentPage === 1 ? logs.indexOf(record) + 1 : (currentPage - 1) * perPage + logs.indexOf(record) + 1;
      }
    },
    {
      title: 'Response Id',
      dataIndex: 'id',
      key: 'id',
      render: (id: string) => {
        return <Tag color="green">{id}</Tag>
      }
    },
    {
      title: 'Date',
      sorter: (a: IBackendLog, b: IBackendLog) => moment(a.created_at || a.updated_at).unix() - moment(b.created_at || b.updated_at).unix(),
      render: (record: IBackendLog) => {
        const formattedDate = moment(record?.created_at || record?.updated_at).format('DD-MM-YYYY hh:mm:ss');
        return formattedDate || <CloseOutlined />;
      }
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
      sorter: (a: IBackendLog, b: IBackendLog) => String(a.all.email).length - String(b.all.email).length,
      render: (email: string) => {
        return email ?? <Tag color="red">Not specified</Tag>;
      }
    },
    {
      title: 'Path',
      dataIndex: 'path',
      key: 'path',
      render: (path: string) => {
        return <pre>{path}</pre> || <CloseOutlined />;
      }
    },
    {
      title: 'Method',
      dataIndex: 'method',
      key: 'method',
      sorter: (a: IBackendLog, b: IBackendLog) => a.method.length - b.method.length,
      render: (method: string) => {
        return <Tag color={MethodColor[method as keyof typeof MethodColor]}>{method}</Tag> || <CloseOutlined />;
      }
    },
    {
      title: 'Response',
      dataIndex: 'response',
      key: 'response',
      render: (response: { code: string, response: string }) => {
        const json = JSON.stringify(response)
          .replace(/\\/g, '')
          .replace('""', '')
          .replace('{}', '"{}"')
          .replace('"",', ',');
        return json !== 'null' ? <div className="response-column">{`${json.substring(0, 25)}...`}</div> : <CloseOutlined />;
      }
    },
    {
      title: 'Code',
      dataIndex: 'response_code',
      key: 'response_code',
      sorter: (a: IBackendLog, b: IBackendLog) => Number(a.response_code) - Number(b.response_code),
      render: (response_code: number) => {
        let statusColor = '';
        switch (response_code) {
          case 200:
            statusColor = 'green';
            break;
          case 500:
            statusColor = 'red';
            break;
          default:
            statusColor = 'orange';
            break;
        }
        return response_code ? <Tag color={statusColor}>{response_code}</Tag> : <CloseOutlined />;
      }
    },
  ];


  return (
    <div className={baseClass}>
      <Table
        tableLayout="fixed"
        columns={columns}
        expandable={{
          expandedRowRender: (record: IBackendLog) => {
            if (!Object.keys(record.response as any)) {
              return;
            }

            return (
              <div className="expandable-content">
                <PrettyJson json={record.response} />
              </div>
            );
          },
          rowExpandable: (record: IBackendLog) => !!record?.response
        }}
        dataSource={logs}
        onChange={onChange}
        loading={isLoading}
        pagination={{
          total,
          current: currentPage,
          pageSize: perPage,
          pageSizeOptions: ['10', '20', '30', '50'],
          onChange: onPaginationChange
        }}
      />
    </div>
  );
};