import { useEffect, useRef, useState } from 'react';
import { Spin } from 'antd';
import { useSelector } from 'react-redux';
import { IBackendLogsResponse, IBackendLog, IBackendLogsFilter, IAuthState } from '../../data-access';
import { logsApi } from '../../api/logs';
import { BackendLogsFilter, BackendLogsTable } from '../../ui';
import {notify, querify} from '../../utils';
import './backend-logs.scss';

const baseClass = 'backend-logs';

export const BackendLogs = (): JSX.Element => {
  const [isFetchingData, setIsFetchingData] = useState<boolean>(false);
  const [backendLogs, setBackendLogs] = useState<IBackendLog[]>([]);
  const [logsPerPage, setLogsPerPage] = useState<number>(10);
  const [totalLogs, setTotalLogs] = useState<number>(10);
  const [sortingParams, setSortingParams] = useState({});
  const [currentPage, setCurrentPage] = useState<number>(1);

  const dataFilterRef = useRef<Partial<IBackendLogsFilter>>({});

  const authUser = useSelector((state: { auth: IAuthState }) => state.auth);

  useEffect(() => {
    processFilter({
      page: currentPage,
      per_page: logsPerPage,
      ...dataFilterRef.current,
      ...(Object.keys(sortingParams).length > 0 && { order_by: querify(sortingParams, ':', ',') })
    });
  }, [currentPage, logsPerPage, sortingParams]);

  const processFilter = (filter: IBackendLogsFilter): void => {
    dataFilterRef.current = filter;
    setIsFetchingData(true);
    logsApi.fetchBackendLogs(filter, authUser.auth_token)
      .then((response: IBackendLogsResponse) => {
        const backendLogs = response.data.map((record: IBackendLog, index: number) => {
          return {
            key: index + 1,
            ...record
          };
        })
        setBackendLogs(backendLogs);
        setTotalLogs(response.meta.total);
      })
      .catch(() => {
        notify('error', 'Failed to fetch data.');
      }).finally(() => setIsFetchingData(false));
  }

  const onPaginationChange = (page: number, pageSize: number): void => {
    setCurrentPage(page);
    setLogsPerPage(pageSize);
  }

  const handleTableChange = (pagination: { current?: number; pageSize?: number; total?: number; }, _: any, sorting: any): void => {
    setLogsPerPage(pagination.pageSize || 10);
    setCurrentPage(pagination.current || 1);
    if (sorting.field) {
      setSortingParams({
        ...sortingParams,
        [sorting.field]: sorting.order === 'ascend' ? 'asc' : 'desc'
      });
    }
  }

  return (
    <div className={baseClass}>
      <BackendLogsFilter onApply={processFilter} />
      <Spin spinning={isFetchingData} tip="Loading...">
        <BackendLogsTable
          logs={backendLogs}
          total={totalLogs}
          currentPage={currentPage}
          perPage={logsPerPage}
          onChange={handleTableChange}
          onPaginationChange={onPaginationChange}
        />
      </Spin>
    </div>
  )
}