import { baseUrl, handleResponse } from './base';
import { IPromoCode } from '../data-access';

const requestOptions = {
  method: 'GET',
  mode: 'cors',
  cache: 'no-cache',
  credentials: 'same-origin',
  headers: {
    'Accept': 'application/json',
    'Content-Type': 'application/json',
  }
} as any;

const fetchPromoCodes = (token: string): Promise<{ data: IPromoCode[] }> => {
  return fetch(`${baseUrl}api/promocodes`, {
    ...requestOptions,
    headers: {
      ...requestOptions.headers ,
      'Authorization': `Bearer ${token}`
    }
  }).then(handleResponse);
}

const createPromoCode = (token: string, data: {
  name: string;
  discount: number | null;
  fromDate: string;
  toDate: string;
  isStarter: boolean;
  isYearly: boolean;
}): Promise<{ data: IPromoCode }> => {
  return fetch(`${baseUrl}api/promocodes`, {
    ...requestOptions,
    method: 'POST',
    body: JSON.stringify(data),
    headers: {
      ...requestOptions.headers ,
      'Authorization': `Bearer ${token}`
    }
  }).then(handleResponse);
}

const updatePromoCode = (token: string, id: number, data: {
  name: string;
  discount: number | null;
  fromDate: string;
  toDate: string;
  isStarter: boolean;
  isYearly: boolean;
}): Promise<{ data: IPromoCode }> => {
  return fetch(`${baseUrl}api/promocodes/${id}`, {
    ...requestOptions,
    method: 'PATCH',
    body: JSON.stringify(data),
    headers: {
      ...requestOptions.headers ,
      'Authorization': `Bearer ${token}`
    }
  }).then(handleResponse);
}

const deletePromoCode = (token: string, id: number): Promise<any> => {
  return fetch(`${baseUrl}api/promocodes/${id}`, {
    ...requestOptions,
    method: 'DELETE',
    headers: {
      ...requestOptions.headers ,
      'Authorization': `Bearer ${token}`
    }
  }).then(handleResponse);
}

export const promoCodesApi = {
  fetchPromoCodes,
  createPromoCode,
  deletePromoCode,
  updatePromoCode
};