import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Modal } from 'antd';
import dayjs from 'dayjs';
import { promoCodesApi } from '../../api/promocodes';
import { IAuthState, ICreateDiscountFormData, IPromoCode } from '../../data-access';
import {notify} from '../../utils';
import { DiscountsTable } from './discounts-table';
import { CreateDiscountForm } from './create-discount-form';
import { UpdateDiscountForm } from './update-discount-form';
import { FormButton } from '../../ui';
import './discounts.scss';

const baseClassName = 'discounts';

function createPayloadForModifyRequests(discount: ICreateDiscountFormData): {
  name: string;
  discount: number | null;
  fromDate: string;
  toDate: string;
  isStarter: boolean;
  isYearly: boolean;
} {
  return {
    name: discount.name,
    discount: discount.discount,
    fromDate: dayjs(discount.startDate).format('YYYY-MM-DD'),
    toDate: dayjs(discount.endDate).format('YYYY-MM-DD'),
    isStarter: !!discount.plans.find((plan: { name: string, checked: boolean }) => plan.name === 'Starter')?.checked || false,
    isYearly: !!discount.plans.find((plan: { name: string, checked: boolean }) => plan.name === 'Yearly')?.checked || false,
  }
}

export const Discounts = (): JSX.Element => {
  const authUser = useSelector((state: { auth: IAuthState }) => state.auth);

  const [isCreateModalOpen, setIsCreateModalOpen] = useState<boolean>(false);
  const [isUpdateModalOpen, setIsUpdateModalOpen] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [discountForEdit, setDiscountForEdit] = useState<IPromoCode>({} as IPromoCode);
  const [discountsList, setDiscountsList] = useState<IPromoCode[]>([]);

  const toggleCreateModal = (): void => {
    setIsCreateModalOpen(!isCreateModalOpen);
  }

  const toggleUpdateModal = (): void => {
    setIsUpdateModalOpen(!isUpdateModalOpen);
  }

  const handleCreateDiscount = (discount: ICreateDiscountFormData): void => {
    setIsLoading(true);
    const payload = createPayloadForModifyRequests(discount);
    promoCodesApi.createPromoCode(authUser.auth_token, payload)
      .then((response: { data: IPromoCode }) => {
        setDiscountsList([...discountsList, response.data]);
        setIsCreateModalOpen(false);
        notify('success', `"${response.data.name}" promocode has been successfully added.`);
      })
      .catch((error: { message: string, status?: number }) => {
        notify('error', error.message);
      })
      .finally(() => setIsLoading(false));
  }

  const handleDiscountDelete = (id: number): void => {
    setIsLoading(true);
    promoCodesApi.deletePromoCode(authUser.auth_token, id)
      .then(() => {
        setDiscountsList([...discountsList.filter(discount => discount.id !== id)]);
        notify('success', `Promocode has been successfully deleted.`);
      })
      .catch((error: { message: string, status?: number }) => {
        notify('error', error.message);
      })
      .finally(() => setIsLoading(false));
  }

  const handleDiscountEdit = (promoCodeId: number): void => {
    const targetDiscount = discountsList
      .find((discount: IPromoCode) => discount.id === promoCodeId);

    if (targetDiscount) {
      setDiscountForEdit(targetDiscount);
      setIsUpdateModalOpen(true);
    }
  }

  const handleUpdateDiscount = (discount: any): void => {
    setIsLoading(true);
    const payload = createPayloadForModifyRequests(discount);
    promoCodesApi.updatePromoCode(authUser.auth_token, discount.id, payload)
      .then((response: { data: IPromoCode }) => {
        notify('success', `"${response.data.name}" promocode has been successfully added.`);
      })
      .catch((error: { message: string, status?: number }) => {
        notify('error', error.message);
      })
      .finally(() => setIsLoading(false));
  }

  useEffect(() => {
    promoCodesApi.fetchPromoCodes(authUser.auth_token)
      .then((response: { data: IPromoCode[] }) => {
        setDiscountsList(response.data);
      })
      .catch((error: { message: string, status?: number }) => {
        notify('error', error.message);
      })
      .finally(() => setIsLoading(false));
  }, []);

  return (
    <div className={baseClassName}>
      <h3 className={`${baseClassName}__title`}>
        Discounts
        <FormButton htmlType="button" onClick={toggleCreateModal}>Create +</FormButton>
      </h3>
      <DiscountsTable
        data={discountsList}
        total={discountsList.length}
        currentPage={1}
        isLoading={isLoading}
        perPage={10}
        onDelete={handleDiscountDelete}
        onEdit={handleDiscountEdit}
      />
      <Modal
        title={"Create New Discount"}
        open={isCreateModalOpen}
        footer={null}
        destroyOnClose={true}
        closeIcon={null}
        confirmLoading={isLoading}
      >
        <CreateDiscountForm
          onCancel={toggleCreateModal}
          onSubmit={handleCreateDiscount}
        />
      </Modal>
      <Modal
        title={"Update Discount"}
        open={isUpdateModalOpen}
        footer={null}
        destroyOnClose={true}
        closeIcon={null}
        confirmLoading={isLoading}
      >
        <UpdateDiscountForm
          data={discountForEdit}
          onCancel={toggleUpdateModal}
          onSubmit={handleUpdateDiscount}
        />
      </Modal>
    </div>
  );
}