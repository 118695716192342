import React from 'react';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import dayjs from 'dayjs';
import { yupResolver } from '@hookform/resolvers/yup';
import { ICreateDiscountFormData }  from '../../../data-access';
import { FormButton, FormInput, FormDatePicker, FormCheckbox } from '../../../ui';
import { validationScheme } from '../discounts-utils';

import './create-discount-form.scss';


const baseClassName = 'create-discount-form';

interface ICreateDiscountFormProps {
  onSubmit: (data: ICreateDiscountFormData) => void,
  onCancel: () => void,
}

export const CreateDiscountForm = ({ onCancel, onSubmit }: ICreateDiscountFormProps): JSX.Element => {
  const form = useForm<ICreateDiscountFormData>({
    mode: 'all',
    defaultValues: {
      name: '',
      discount: null,
      startDate: '',
      endDate: dayjs(),
      plans: [
        { name: 'Starter', checked: false },
        { name: 'Yearly', checked: false },
      ],
    },
    resolver: yupResolver(validationScheme) as any,
  });
  const { control, handleSubmit, formState: { errors, isDirty, isValid } } = form;
  const { fields } = useFieldArray({
    control,
    name: 'plans',
  });

  return (
    <div className={baseClassName}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Controller
          control={control}
          name="name"
          render={({ field: { name, onBlur, value, ref, onChange } }) => (
            <FormInput
              ref={ref}
              label="Name"
              placeholder="Enter name"
              error={errors?.name?.message}
              name={name}
              value={value}
              onBlur={onBlur}
              onChange={onChange}
            />
          )}
        />
        <Controller
          control={control}
          name="discount"
          render={({ field: { name, onBlur, onChange, value, ref } }) => (
            <FormInput
              ref={ref}
              label="Discount, %"
              type="number"
              placeholder="Enter discount"
              error={errors?.discount?.message}
              name={name}
              value={String(value)}
              onBlur={onBlur}
              onChange={onChange}
            />
          )}
        />
        <div className="field-group">
          <span>Discount period</span>
          <div className="row">
            <Controller
              control={control}
              name="startDate"
              render={({ field: { name, onChange, value, ref } }) => (
                <FormDatePicker
                  name={name}
                  ref={ref}
                  value={value ? dayjs(value, 'YYYY-MM-DD') : undefined}
                  error={errors?.startDate?.message}
                  onChange={(value) => {
                    onChange(value);
                    form.trigger();
                  }}
                />
              )}
            />
            <Controller
              control={control}
              name="endDate"
              render={({ field: { name, onChange, value, ref } }) => (
                <FormDatePicker
                  name={name}
                  ref={ref}
                  value={value ? dayjs(value, 'YYYY-MM-DD') : undefined}
                  error={errors?.endDate?.message}
                  onChange={(value) => {
                    onChange(value);
                    form.trigger();
                  }}
                />
              )}
            />
          </div>
        </div>
        <div className="field-group">
          <>
            <span>Discount plan</span>
            <div className="row">
              {fields.map((field, index) => (
                <Controller
                  render={({ field }) => (
                    <FormCheckbox
                      {...field}
                      value={field.value.checked}
                      onChange={() => {
                        const actualizedCheckbox = { name: field.value.name, checked: !field.value.checked }
                        field.onChange(actualizedCheckbox);
                        form.trigger();
                      }}
                    >{field.value.name}</FormCheckbox>
                  )}
                  name={`plans.${index}`}
                  control={control}
                />
              ))}
            </div>
            {errors?.plans?.root?.message && <p className="field-input__error">{errors.plans.root.message}</p>}
          </>
        </div>
        <div className="form-controls">
          <FormButton theme="blue" onClick={onCancel}>Cancel</FormButton>
          <FormButton disabled={!isDirty || !isValid} htmlType="submit">Submit</FormButton>
        </div>
      </form>
    </div>
  );
}