import { Navigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { Layout } from '../layout';
import { APP_ROUTES } from '../../data-access';
import { RootState } from '../../store';

interface IPrivateRouteProps {
  children: JSX.Element;
}

export const PrivateRoute = ({ children }: IPrivateRouteProps): JSX.Element => {
  const isAuthorized = useSelector((state: RootState) => state.auth?.auth_token);

  if (!isAuthorized) {
    return <Navigate to={APP_ROUTES.home} />;
  }

  return <Layout>{children}</Layout>;
};

PrivateRoute.propTypes = {
  isForAdminOnly: PropTypes.bool,
  children: PropTypes.element.isRequired,
};