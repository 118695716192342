import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IAuthState } from '../../data-access';

const INITIAL_STATE = {} as IAuthState;

export const authSlice = createSlice({
  name: 'auth',
  initialState: INITIAL_STATE,
  reducers: {
    setUserData: (state = INITIAL_STATE, action: PayloadAction<Partial<IAuthState>>) => ({
      ...state,
      ...action.payload
    }),
    resetAuthData: () => ({ ...INITIAL_STATE }),
  }
});

// this is for dispatch
export const storeAuthActions = authSlice.actions;

// this is for configureStore
const authReducer = authSlice.reducer;

export default authReducer;