import { useEffect, useRef, useState } from 'react';
import { Spin } from 'antd';
import { useSelector } from 'react-redux';
import {
  IExtensionLogsResponse,
  IExtensionLog,
  IExtensionLogsFilter,
  IAuthState,
} from '../../data-access';
import { logsApi } from '../../api/logs';
import { ExtensionLogsFilter, ExtensionLogsTable } from '../../ui';
import { notify, querify } from '../../utils';
import './extension-logs.scss';

const baseClass = 'extension-logs';

export const ExtensionLogs = (): JSX.Element => {
  const [isFetchingData, setIsFetchingData] = useState<boolean>(false);
  const [extensionLogs, setExtensionLogs] = useState<IExtensionLog[]>([]);
  const [totalLogs, setTotalLogs] = useState<number>(10);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [logsPerPage, setLogsPerPage] = useState<number>(10);
  const [sortingParams, setSortingParams] = useState({});

  const dataFilterRef = useRef<Partial<IExtensionLogsFilter>>({});

  const authUser = useSelector((state: { auth: IAuthState }) => state.auth);

  useEffect(() => {
    processFilter({
      page: currentPage,
      per_page: logsPerPage,
      ...dataFilterRef.current,
      ...(Object.keys(sortingParams).length > 0 && { order_by: querify(sortingParams, ':', ',') })
    });
  }, [currentPage, logsPerPage, sortingParams]);

  const processFilter = (filter: IExtensionLogsFilter): void => {
    dataFilterRef.current = filter;
    setIsFetchingData(true);
    logsApi.fetchExtensionLogs({
        ...filter,
        page: currentPage,
        per_page: logsPerPage,
        ...(Object.keys(sortingParams).length > 0 && { order_by: querify(sortingParams, ':', ',') })
      }, authUser.auth_token)
      .then((response: IExtensionLogsResponse) => {
        const updatedLogs = response.data.map((record: IExtensionLog, index: number) => ({
          key: index + 1,
          ...record
        }))
        setExtensionLogs(updatedLogs);
        setTotalLogs(response.meta.total);
        setCurrentPage(response.meta.current_page)
      })
      .catch(() => {
        notify('error', 'Failed to fetch data.');
      }).finally(() => setIsFetchingData(false));
  }

  const handleTableChange = (pagination: { current?: number; pageSize?: number; total?: number; }, _: any, sorting: any): void => {
    setLogsPerPage(pagination.pageSize || 10);
    setCurrentPage(pagination.current || 1);
    if (sorting.field) {
      setSortingParams({
        ...sortingParams,
        [sorting.field]: sorting.order === 'ascend' ? 'asc' : 'desc'
      });
    }
  }

  const onPaginationChange = (page: number, pageSize: number): void => {
    setCurrentPage(page);
    setLogsPerPage(pageSize);
  }

  return (
    <div className={baseClass}>
      <ExtensionLogsFilter onApply={processFilter} />
      <Spin spinning={isFetchingData} tip="Loading...">
        <ExtensionLogsTable
          logs={extensionLogs}
          total={totalLogs}
          currentPage={currentPage}
          perPage={logsPerPage}
          onChange={handleTableChange}
          onPaginationChange={onPaginationChange}
        />
      </Spin>
    </div>
  )
};