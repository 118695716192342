import { ChangeEvent } from 'react';
import { Controller, useForm } from 'react-hook-form';
import PropTypes from 'prop-types';
import dayjs, { Dayjs } from 'dayjs';
import debounce from 'lodash.debounce';
import { IExtensionLogsFilter } from '../../data-access';
import { LOG_ACTIONS } from '../../api/logs';
import { FormButton, FormDatePicker, FormInput, FormSelect } from '../form';
import './extension-logs-filter.scss';

const baseClass = 'extension-logs-filter';

const actionFilterOptions = LOG_ACTIONS.map((logAction: string) => ({
  label: logAction, value: logAction
}));
actionFilterOptions.unshift({ label: 'All', value: 'all' });

interface IExtensionLogsFilterProps {
  onApply: (filter: IExtensionLogsFilter) => void;
}

type FieldNameType = 'email' | 'action' | 'method' | 'created_at';

export const ExtensionLogsFilter = ({ onApply }: IExtensionLogsFilterProps): JSX.Element => {
  const formDefaultValues = {
    email: '',
    action: 'all',
    method: '',
    created_at: ''
  };

  const form = useForm({
    mode: 'onSubmit',
    defaultValues: formDefaultValues,
  });

  const { control, handleSubmit, setValue, reset, formState: { errors, dirtyFields } } = form;

  const handleEmailFilterChange = debounce(({ name, value }: { name: FieldNameType, value: string }) => {
    setValue(name, value, { shouldDirty: true, shouldValidate: true });
  }, 300);

  const handleSelectFilterChange = ({ name, value }: { name: FieldNameType, value: string }): void => {
    setValue(name, value, { shouldDirty: true, shouldValidate: true });
  }

  const handleFilterApply = (filter: IExtensionLogsFilter): void => {
    if (filter.action === 'all') {
      filter.action = '';
    }

    onApply(filter);
  }

  const isFormValid = Object.keys(dirtyFields).length > 0 && !errors?.email?.message;

  return (
    <div className={baseClass}>
      <form className={`${baseClass}__container`} onSubmit={handleSubmit(handleFilterApply)}>
        <Controller
          control={control}
          name={'email' as FieldNameType}
          render={({ field: { name, value, onChange, ref } }) => (
            <FormInput
              allowClear={true}
              ref={ref}
              value={value || ''}
              label="Email"
              type="email"
              name={name}
              error={errors?.email?.message}
              placeholder={`Please specify email`}
              onChange={(event: ChangeEvent<HTMLInputElement>) => {
                onChange(event.target.value);
                handleEmailFilterChange({ name: 'email' as FieldNameType, value: event.target.value });
              }}
            />
          )}
        />
        <Controller
          control={control}
          name={'method' as FieldNameType}
          render={({ field: { name, value, onChange, ref } }) => (
            <FormInput
              allowClear={true}
              ref={ref}
              value={value || ''}
              label="Method"
              type="text"
              name={name}
              error={errors?.method?.message}
              placeholder={`Please specify method`}
              onChange={(event: ChangeEvent<HTMLInputElement>) => {
                onChange(event.target.value);
                handleEmailFilterChange({ name: 'method' as FieldNameType, value: event.target.value });
              }}
            />
          )}
        />
        <Controller
          control={control}
          name="action"
          render={({ field: { onChange, value, ref } }) => (
            <FormSelect
              ref={ref}
              value={value || ''}
              label="Action"
              allowClear={true}
              placeholder="Please select an action"
              options={actionFilterOptions}
              onChange={(value: string) => {
                onChange(value);
                handleSelectFilterChange({ name:'action', value });
              }}
            />
          )}
        />
        <Controller
          control={control}
          name="created_at"
          render={({ field: { name, onChange, value } }) => (
            <FormDatePicker
              value={value ? dayjs(value) : dayjs()}
              label="Created at"
              disableFutureDates={true}
              onChange={(date: Dayjs | null, dateString: string) => {
                onChange(dateString);
                setValue(name, dateString, { shouldDirty: true, shouldValidate: true });
              }}
            />
          )}
        />
        <FormButton
          htmlType="submit"
          disabled={!isFormValid}
        >
          Apply
        </FormButton>
        <FormButton
          htmlType="button"
          disabled={Object.keys(dirtyFields).length === 0}
          onClick={() => {
            reset(formDefaultValues, { keepValues: false });
            handleFilterApply(formDefaultValues);
          }}
        >
          Reset
        </FormButton>
      </form>
    </div>
  );
}

ExtensionLogsFilter.propTypes = {
  onApply: PropTypes.func.isRequired,
};