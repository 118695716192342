import PropTypes from 'prop-types';
import { FocusEventHandler, forwardRef, ForwardedRef } from 'react';
import { Input } from 'antd';
import './form-input.scss';

interface IFormInputProps {
  onChange: FocusEventHandler<HTMLInputElement>;
  onBlur?: FocusEventHandler<HTMLInputElement>;
  label?: string;
  name?: string;
  type?: 'text' | 'password' | 'email' | 'search' | 'number';
  error?: string;
  placeholder?: string;
  className?: string;
  disabled?: boolean;
  allowClear?: boolean;
  value?: string;
}

export const FormInput = forwardRef(({
  value,
  error = '',
  type = 'text',
  name,
  label,
  placeholder,
  disabled = false,
  onBlur,
  onChange,
  allowClear = false,
  className = '' }: IFormInputProps,
  ref: ForwardedRef<any>
): JSX.Element => {
  return (
    <div className="field-input">
      {label && <label className="field-input__label">{label}</label>}
      <Input
        allowClear={allowClear}
        className={`${className} form-input`}
        ref={ref}
        name={name}
        type={type}
        status={error ? 'error' : undefined}
        placeholder={placeholder}
        onBlur={onBlur}
        onChange={onChange}
        disabled={disabled}
        value={value}
      />
      {error && <p className="field-input__error">{error}</p>}
    </div>
  );
});

FormInput.propTypes = {
  name: PropTypes.string.isRequired,
  error: PropTypes.string,
  label: PropTypes.string,
  allowClear: PropTypes.bool,
  type: PropTypes.oneOf(['text', 'password', 'email', 'search']),
  placeholder: PropTypes.string,
  onBlur: PropTypes.func,
  onChange: PropTypes.func.isRequired,
  className: PropTypes.string,
  value: PropTypes.string,
  disabled: PropTypes.bool
};

export default FormInput;