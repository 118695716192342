import { Navigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import { APP_ROUTES } from '../../data-access';

interface IProtectedRouteProps {
  renderCondition: boolean;
  children: JSX.Element;
}

export const ProtectedRoute = ({ renderCondition, children }: IProtectedRouteProps): JSX.Element => {
  if (!renderCondition) {
    return <Navigate to={APP_ROUTES.extensionLogs} />;
  }
  return <>{children}</>;
};

ProtectedRoute.propTypes = {
  renderCondition: PropTypes.bool.isRequired,
  children: PropTypes.element.isRequired,
};