import { ReactNode, useState } from 'react';
import { FaChevronDown, FaChevronUp } from 'react-icons/fa';
import './expandable.scss';

const baseClassName = 'expandable';

interface IExpandableProps {
  title: string;
  children: string | ReactNode;
}

export const Expandable = ({ title, children }: IExpandableProps): JSX.Element => {
  const [isOpen, setIsOpen] =  useState<boolean>(false);

  const toggleExpandable = (): void => {
    setIsOpen(!isOpen);
  }

  return (
    <div className={baseClassName}>
      <h3 className={`${baseClassName}__title`} onClick={toggleExpandable}>
        {title} {isOpen ? <FaChevronUp />: <FaChevronDown />}</h3>
      {isOpen && <div className={`${baseClassName}__content`}>{children}</div>}
    </div>
  );
}