import { IBackendLogsFilter, IBackendLogsResponse, IExtensionLogsFilter, IExtensionLogsResponse } from '../data-access';
import { baseUrl, handleResponse, serializeQuery } from './base';

const requestOptions = {
  method: 'GET',
  mode: 'cors',
  cache: 'no-cache',
  credentials: 'same-origin',
  headers: {
    'Accept': 'application/json',
    'Content-Type': 'application/json',
  }
} as any;

export const LOG_ACTIONS = [
  'REQUEST_SUCCESS',
  'REQUEST_FAILED',
  'EXTENSION_ERROR',
  'EXTENSION_INSTALLED',
  'EXTENSION_UPDATED'
];

const fetchExtensionLogs = (params: IExtensionLogsFilter, token: string): Promise<IExtensionLogsResponse> => {
  const queryParams = params && Object.values(params).filter(Boolean).length ? `?${serializeQuery(params)}` : '';
  return fetch(`${baseUrl}api/logs/ext${queryParams}`, {
    ...requestOptions,
    headers: {
      ...requestOptions.headers ,
      'Authorization': `Bearer ${token}`
    }
  }).then(handleResponse);
}

const fetchBackendLogs = (params: IBackendLogsFilter, token: string): Promise<IBackendLogsResponse> => {
  const queryParams = params && Object.values(params).filter(Boolean).length ? `?${serializeQuery(params)}` : '';
  return fetch(`${baseUrl}api/logs/be${queryParams}`, {
    ...requestOptions,
    headers: {
      ...requestOptions.headers ,
      'Authorization': `Bearer ${token}`
    }
  }).then(handleResponse);
}

export const logsApi = {
  fetchExtensionLogs,
  fetchBackendLogs
};