import PropTypes from 'prop-types';
import React from 'react';
import { Sidebar } from '../sidebar';
import { TopBarPanel } from '../top-bar-panel';
import './layout.scss';

interface LayoutProps {
  children: React.ReactNode;
}

export const Layout = ({ children }: LayoutProps): JSX.Element => {
  return (
    <div className="layout">
      <Sidebar />
      <div className="content">
        <TopBarPanel />
        {children}
      </div>
    </div>
  );
}

export default Layout;

Layout.propTypes = {
  children: PropTypes.element.isRequired
};