export const baseUrl = 'https://logs-api.upwex.io/';

export const handleResponse = (response: Response) => {
  if (!response.ok) {
    return response
      .text()
      .then((result: any) => JSON.parse(result))
      .then((result: any) => Promise.reject({ status: result.status, message: result.message }));
  }
  return response
    .json()
    .then((result: any) => Promise.resolve(result))
    .catch(() => Promise.resolve({ status: response.status, message: response.statusText }));
};

export const serializeQuery = (params: { [key: string]: any }): string => {
  const query = Object.keys(params).map((key: string) => {
    const value = params[key];
    return value ? `${key}=${encodeURIComponent(value)}` : '';
  });
  return query.filter(Boolean).join('&');
}