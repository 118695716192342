import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { APP_ROUTES, IAuthState } from '../../data-access';
import { authApi } from '../../api/auth';
import { storeAuthActions } from '../../store/slices/auth';

export const useUser = () => {
  const authUser = useSelector((state: { auth: IAuthState }) => state.auth);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const resetUser = (): void => {
    authApi.logout({ token: authUser?.auth_token }).then().catch().finally(() => {
      dispatch(storeAuthActions.resetAuthData());
      navigate(APP_ROUTES.home);
    });
  }

  return {
    currentUser: !!authUser?.auth_token ? authUser : null,
    resetUser,
  };
};
