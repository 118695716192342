import React, { useEffect, useState } from "react";
import { Switch } from "antd";
import { useSelector } from "react-redux";
import moment from "moment";
import { subscriptionsApi } from "../../api/subscriptions";
import {
  ChartMetrics,
  IAuthState,
  ISubscription,
  LicencePlan,
  ISubscriptionsBlock,
  UsersCard,
} from "../../data-access";
import { FormSelect, Panel } from "../../ui";
import { notify, sortBy } from "../../utils";
import { PaymentsChart } from "./payments-chart";
import { SubscriptionsTable } from "./subscriptions-table";
import { SubscriptionsChart } from "./subscriptions-chart";
import { SubscriptionCard } from "./subscription-card";
import { SubscriptionChartDetailsTable } from "./subscription-chart-details-table";
import "./subscriptions.scss";

const baseClassName = "subscriptions";

export const Subscriptions = (): JSX.Element => {
  const [isSubscriptionsLoading, setIsSubscriptionsLoading] =
    useState<boolean>(false);
  const [isBlocksLoading, setIsBlocksLoading] = useState<boolean>(false);
  const [isNewSubscriptionOnly, setIsNewSubscriptionOnly] =
    useState<boolean>(false);
  const [subscriptions, setSubscriptions] = useState<
    { plan: LicencePlan; subscriptions: ISubscription[] }[]
  >([]);
  const [selectedYear, setSelectedYear] = useState<string>(`1`);
  const [blocks, setBlocks] = useState<ISubscriptionsBlock[]>([]);
  const [dataUsers, setDataUsers] = useState<any>({});
  const authUser = useSelector((state: { auth: IAuthState }) => state.auth);

  const fetchSubscriptions = (): void => {
    setIsSubscriptionsLoading(true);
    subscriptionsApi
      .activeUsers(authUser.auth_token, selectedYear)
      .then((response: { data: any }) => {
        setDataUsers(response.data);

        const processedResponse = [] as any;
        for (const [key, value] of Object.entries(response.data)) {
          processedResponse.push({
            plan: UsersCard[key as keyof typeof UsersCard],
            subscriptions: value,
          });
        }

        setSubscriptions(processedResponse);
      })
      .catch((error) => {
        notify("error", error.message);
      })
      .finally(() => {
        setIsSubscriptionsLoading(false);
      });
  };

  const getCountDataByName = (type: string): ISubscriptionsBlock => {
    const count = dataUsers[type]?.length;
    return count
      ? {
          name: UsersCard[type as keyof typeof UsersCard],
          subscriptions: count,
          type,
        }
      : ({} as ISubscriptionsBlock);
  };

  const handleDetailsDataSort = (
    plan: LicencePlan,
    field: string,
    order: "asc" | "desc"
  ): void => {
    const subscriptionsClone = [...subscriptions];
    const targetPlanIndex = subscriptions.findIndex((sub) => sub.plan === plan);
    const sorterArray = subscriptionsClone[targetPlanIndex].subscriptions.sort(
      sortBy(field)
    );
    subscriptionsClone[targetPlanIndex].subscriptions =
      order === "asc" ? sorterArray : sorterArray.reverse();
    setSubscriptions(subscriptionsClone);
  };

  useEffect(() => {
    fetchSubscriptions();
  }, [selectedYear]);

  return (
    <div className={baseClassName}>
      <div className={`${baseClassName}__cards`}>
        <SubscriptionCard
          isLoading={isSubscriptionsLoading}
          data={getCountDataByName("totalUsers")}
        />
        <SubscriptionCard
          isLoading={isSubscriptionsLoading}
          data={getCountDataByName("paidUsers")}
        />
        <SubscriptionCard
          isLoading={isSubscriptionsLoading}
          data={getCountDataByName("trialUsers")}
        />
        <SubscriptionCard
          isLoading={isSubscriptionsLoading}
          data={getCountDataByName("activeUsers")}
        />
        <SubscriptionCard
          isLoading={isSubscriptionsLoading}
          data={getCountDataByName("pipedriveUsers")}
        />
      </div>
      <Panel
        title="Users"
        className={baseClassName}
        isLoading={isSubscriptionsLoading}
        nodeInTitleRow={
          <div className="filters">
            <FormSelect
              value={`${selectedYear}`}
              options={[
                { label: "Last 24 hours", value: "1" },
                { label: "Last 7 days", value: "7" },
                { label: "Last 14 days", value: "14" },
                { label: "Last 30 days", value: "30" },
              ]}
              onChange={setSelectedYear}
            />
          </div>
        }
      >
        <SubscriptionsTable
          data={subscriptions || []}
          onDetailsSort={handleDetailsDataSort}
        />
      </Panel>
    </div>
  );
};
